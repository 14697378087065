<template>
    <app-layout>
        <div class="container">
            <div class="row justify-content-md-center mt-4">
                <div class="col-7">
                    <div class="card">
                        <div class="card-body">
                            Acceso Denegado
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout'

export default {
    components: {
        AppLayout
    },
}
</script>