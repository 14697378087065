<template>
    <app-layout>
        <template #header>
            <h2 v-if="servicio == 1" class="font-semibold text-xl text-gray-800 leading-tight">
                Reserva de horas Laboratorio Clínico
            </h2>
            <h2 v-if="servicio == 2" class="font-semibold text-xl text-gray-800 leading-tight">
                Reserva de horas Servicio de Imágenes
            </h2>
            <h2 v-if="servicio == 3" class="font-semibold text-xl text-gray-800 leading-tight">
                Reserva de horas Servicio de Endoscopía
            </h2>
            <h2 v-if="servicio == 4" class="font-semibold text-xl text-gray-800 leading-tight">
                Reserva de horas Servicio de Cardiología
            </h2>
            <h2 v-if="servicio == 5" class="font-semibold text-xl text-gray-800 leading-tight">
                Reserva de horas Servicio de Hemato - Oncología
            </h2>
            <h2 v-if="servicio == 6" class="font-semibold text-xl text-gray-800 leading-tight">
                Otros Servicios
            </h2>
            <h2 v-if="servicio == 7" class="font-semibold text-xl text-gray-800 leading-tight">
                Agenda Hora Vacuna COVID
            </h2>
        </template>
        <div v-if="!loading_table" class="row m-0 justify-content-center align-items-center vh-100">
            <div class="col text-center">
                <div class="spinner-border spinner-alemana" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div v-if="loading_table" class="container">
            <div class="row justify-content-md-center mt-4">
                        <div class="col-md-4">
                            <input type="date" v-on:change="cambiarfecha()" v-model="selectweek" name="semana" id="semana" class="form-control" :min="datefilter_start" :max="datefilter_end">
                        </div>
                    </div>
                    <div class="row justify-content-md-center mt-2">
                        <div class="col-md-4 text-center">{{ selectweek == '' ? 'Seleccione una semana' : diasemana }}</div>
                    </div>
                    <div v-if="selectweek != ''" class="row mt-2 scroll-test tableFixHead">
                        <div class="col">
                            <table class="table table-bordered table-striped">
                                <thead class="text-center">
                                    <tr>
                                        <th class="color-table-alemana" scope="col"><button v-on:click.prevent="borrarBuscar()" data-toggle="modal" data-target="#buscarRut" class="btn button-green-alemana">Buscar</button></th>
                                        <th :class="close_lu ? 'color-red-alemana' : 'color-table-alemana'" scope="col">LUNES<div class="text-small">{{ showdiatitletable(8) }}</div></th>
                                        <th :class="close_ma ? 'color-red-alemana' : 'color-table-alemana'" scope="col">MARTES<div class="text-small">{{ showdiatitletable(9) }}</div></th>
                                        <th :class="close_mi ? 'color-red-alemana' : 'color-table-alemana'" scope="col">MIERCOLES<div class="text-small">{{ showdiatitletable(10) }}</div></th>
                                        <th :class="close_ju ? 'color-red-alemana' : 'color-table-alemana'" scope="col">JUEVES<div class="text-small">{{ showdiatitletable(11) }}</div></th>
                                        <th :class="close_vi ? 'color-red-alemana' : 'color-table-alemana'" scope="col">VIERNES<div class="text-small">{{ showdiatitletable(12) }}</div></th>
                                        <th :class="close_sa ? 'color-red-alemana' : 'color-table-alemana'" scope="col">SABADO<div class="text-small">{{ showdiatitletable(13) }}</div></th>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr v-for="(data, n) in dataschedule" :key="n">
                                        <td>{{ data.time }}</td>
                                        <td :class="close_lu ? 'pointer-table bloq-cupo' : 'pointer-table ' + data.mo.color" data-toggle="modal" :data-target="data.mo.bloqmodal || close_lu ? '' : '#modalAgend'" v-on:click.prevent="showmodalagend(data.mo.row,data.mo.col,data.time,data.mo.exist)">{{ data.mo.value }}</td>
                                        <td :class="close_ma ? 'pointer-table bloq-cupo' : 'pointer-table ' + data.tu.color" data-toggle="modal" :data-target="data.tu.bloqmodal || close_ma ? '' : '#modalAgend'" v-on:click.prevent="showmodalagend(data.tu.row,data.tu.col,data.time,data.tu.exist)">{{ data.tu.value }}</td>
                                        <td :class="close_mi ? 'pointer-table bloq-cupo' : 'pointer-table ' + data.we.color" data-toggle="modal" :data-target="data.we.bloqmodal || close_mi ? '' : '#modalAgend'" v-on:click.prevent="showmodalagend(data.we.row,data.we.col,data.time,data.we.exist)">{{ data.we.value }}</td>
                                        <td :class="close_ju ? 'pointer-table bloq-cupo' : 'pointer-table ' + data.th.color" data-toggle="modal" :data-target="data.th.bloqmodal || close_ju ? '' : '#modalAgend'" v-on:click.prevent="showmodalagend(data.th.row,data.th.col,data.time,data.th.exist)">{{ data.th.value }}</td>
                                        <td :class="close_vi ? 'pointer-table bloq-cupo' : 'pointer-table ' + data.fr.color" data-toggle="modal" :data-target="data.fr.bloqmodal || close_vi ? '' : '#modalAgend'" v-on:click.prevent="showmodalagend(data.fr.row,data.fr.col,data.time,data.fr.exist)">{{ data.fr.value }}</td>
                                        <td :class="close_sa ? 'pointer-table bloq-cupo' : 'pointer-table ' + data.sa.color" data-toggle="modal" :data-target="data.sa.bloqmodal || close_sa ? '' : '#modalAgend'" v-on:click.prevent="showmodalagend(data.sa.row,data.sa.col,data.time,data.sa.exist)">{{ data.sa.value }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="footer-copyright text-center py-3">© {{ year() }} Copyright:
                                <a id="copyright" href="https://www.clinicaalemanavaldivia.cl/">Clinica Alemana de Valdivia</a>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="modalAgend" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Agenda</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div v-if="datapaciente[0] != null" class="card-body">
                                <div class="scroll-test">
                                    <table class="table table-bordered table-striped">
                                        <thead class="color-table-alemana">
                                            <tr>
                                                <th scope="col">Box</th>
                                                <th scope="col">Reserva</th>
                                                <th scope="col">Rut</th>
                                                <th scope="col">Nombre</th>
                                                <th scope="col">Telefono</th>
                                                <th scope="col">Correo</th>
                                                <th scope="col">Dirección</th>
                                                <th scope="col" style="width:200px"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="data in datapaciente" :key="data.id">
                                                <td>{{ data.name }}</td>
                                                <td>{{ data.id }}</td>
                                                <td>{{ data.rut }}</td>
                                                <td>{{ data.nombre }}</td>
                                                <td>{{ data.telefono }}</td>
                                                <td>{{ data.correo }}</td>
                                                <td>{{ data.direccion }}</td>
                                                <td>
                                                    <a :href="'/getreservapdf/' + servicio + '/' + data.rut + '/' + data.id" role="button" target="_blank" class="btn button-blue-alemana">Imprimir</a>
                                                    <button data-toggle="modal" v-on:click.prevent="messagedelete(data.id)" data-target="#confirmdelete" class="btn btn-danger">Anular</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <button v-if="pacientes_count < total_boxes" v-on:click.prevent="addreserva()" data-toggle="modal" data-target="#modalAdd" class="btn button-blue-alemana">Añadir Reserva</button>
                            </div>
                            <div v-if="datapaciente[0] == null" class="modal-body">
                                <div>Reserva Disponible</div><br>
                                <button v-if="pacientes_count < total_boxes" v-on:click.prevent="addreserva()" data-toggle="modal" data-target="#modalAdd" class="btn button-blue-alemana">Añadir Reserva</button>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="modalAdd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Añadir Reserva</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="alert alert-alemana" role="alert" v-if="errors.length">
                                    <button type="button" class="close" v-on:click.prevent="errors = []" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <b>Por favor corriga los siguientes errores:</b>
                                    <ul>
                                    <li v-for="error in errors" :key="error">{{ error }}</li>
                                    </ul>
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <div class="form-group">
                                            <label for="txt-rut">RUT</label>
                                            <input type="text" v-model="rut" class="form-control upper" id="in-rut">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <div class="form-group">
                                            <label for="txt-nombre">Nombre</label>
                                            <input type="text" v-model="name" class="form-control upper" id="in-nombre">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <div class="form-group">
                                            <label for="txt-direccion">Dirección</label>
                                            <input type="text" v-model="address" class="form-control upper" id="in-direccion">
                                        </div>
                                    </div>            
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <div class="form-group">
                                            <label for="txt-celular">Celular</label>
                                            <input type="text" v-model="telephone" class="form-control" id="in-celular">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <div class="form-group">
                                            <label for="txt-correo">Correo</label>
                                            <input type="email" v-model="email" class="form-control" id="in-correo">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                <div class="col-md">
                                        <div class="form-group">
                                            <label for="txt-dia">Dia</label>
                                            <input disabled type="text" v-model="fecha_modal" class="form-control" id="in-dia">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                <div class="col-md">
                                        <div class="form-group">
                                            <label for="txt-hora">Hora</label>
                                            <input disabled type="text" v-model="hora_modal" class="form-control" id="in-hora">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button type="button" v-on:click.prevent="savereserva()" class="btn button-blue-alemana">Reservar</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="exitoPDF" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Reserva</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body text-center">
                                <div>
                                    Su reserva se ha ingresado correctamente, descargue su comprobante para respaldo. Para anulaciones o consultas, llame al numero (56)632246100
                                </div>
                                <a :href="'/getreservapdf/' + servicio + '/' + rut_exito + '/' + id_exito" target="_blank" role="button" class="btn button-blue-alemana">Descargar PDF</a>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="buscarRut" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-xl">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Buscar</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="input-group mb-3">
                                    <input type="text" v-model="buscar_rut" class="form-control upper" placeholder="Ingrese Rut de Paciente" aria-describedby="button-addon2">
                                    <div class="input-group-append">
                                        <button class="btn button-blue-alemana" v-on:click.prevent="BuscarRut()" type="button" id="button-addon2">Buscar</button>
                                    </div>
                                </div>
                                <div class="scroll-test">
                                    <table class="table">
                                        <thead class="color-table-alemana">
                                            <tr>
                                                <th scope="col">Reserva</th>
                                                <th scope="col">Rut</th>
                                                <th scope="col">Nombre</th>
                                                <th scope="col">Telefono</th>
                                                <th scope="col">Correo</th>
                                                <th scope="col">Fecha Reserva</th>
                                                <th scope="col">Hora Reserva</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="pac in paciente_rut" :key="pac.id">
                                                <td>{{ pac.id }}</td>
                                                <td>{{ pac.rut }}</td>
                                                <td>{{ pac.nombre }}</td>
                                                <td>{{ pac.telefono }}</td>
                                                <td>{{ pac.correo }}</td>
                                                <td>{{ formatofecha(pac.fecha_reserva) }}</td>
                                                <td>{{ formatohora(pac.hora_reserva) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="confirmdelete" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-sm">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Confirmar Anulación</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div>¿Esta seguro de eliminar la reserva numero <span class="texto-blue-alemana">{{ delete_reserva }}</span>? Una vez anulada no se podra recuperar.</div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                <button type="button" v-on:click.prevent="deleteschedule()" class="btn btn-danger">Anular Reserva</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <ScrollTopArrow></ScrollTopArrow>
        </div>
    </app-layout>
</template>

<style>
a:link, a:visited, a:active a:hover{
    color: black;
    text-decoration:none;
}
@media screen and (max-width: 700px) {

.scroll-test{
    overflow-x: scroll;
}
}
@media screen and (min-width: 700px) and (max-width: 1500px) {

.scroll-test{
    overflow-y: none;
}
.tableFixHead{
    height: 100px;
}
.tableFixHead thead th{
    position: sticky;
    top: 0;
}
}

@media screen and (min-width: 1500px) {
 #logo{
margin-left: 20%;
width: 100%;
}
#tit{
    font-size: 20px;
}.tableFixHead{
    height: 100px;
}
.tableFixHead thead th{
    position: sticky;
    top: 0;
}
}
.pointer-table{
    cursor: pointer;
}
.bootstrap-datetimepicker-widget tr:hover {
    background-color: #808080;
}
.text-small{
    font-size: 10px;
}
.color-table-alemana{
    background-color: #407EC9 ;
    color: white;
}
.celda-reservado{
    background-color: #00A499;
    color: white;
}
.button-blue-alemana{
    background-color: #407EC9 !important;
    color: white !important;
}
.button-green-alemana{
    background-color: #00A499 !important;
    color: white !important;
}
.texto-blue-alemana{
    color: #407EC9;
}
.toast-success {
    background-color: #407EC9 !important;
}
.alert-alemana{
    background-color: #407EC9 !important;
}
.upper {
    text-transform: uppercase;
}
.no-cupo{
    background-color: #cd6269;
    color: white;
}
.disp-cupo{
    background-color: #00A499;
    color: white;
}
.color-red-alemana{
    background-color: #797979;
    color: white;
}
.bloq-cupo{
    background-color: #d2d2d2;
}
.spinner-alemana{
    color:  #00A499 !important;
}
</style>

<script>
    import AppLayout from '@/Layouts/AppLayout'
    import ScrollTopArrow from '@/components/ScrollTopArrow'
    import axios from 'axios'
    import toastr from 'toastr'
    export default {
        components: {
            AppLayout,
            ScrollTopArrow,
        },
        data: function(){
        return {
            servicio: '',
            numfilas: 720,
            start_date: '',
            selectrow: '',
            selectcol: '',
            selectweek: '',
            diasemana: '',
            interval: '',
            rut: '',
            rut_exito: '',
            id_exito: '',
            name: '',
            email: '',
            address: '',
            telephone: '',
            datefilter_start: '',
            datefilter_end: '',
            hora_modal: '',
            fecha_modal: '',
            total_boxes: '',
            pacientes_count: '',
            close_lu: false,
            close_ma: false,
            close_mi: false,
            close_ju: false,
            close_vi: false,
            close_sa: false,
            week_s: '',
            week_e: '',
            buscar_rut: '',
            delete_reserva: '',
            loading_table: false,
            dataschedule: [],
            listschedule: [],
            datapaciente: [],
            datadefaultpage: [],
            feriado_semana: [],
            paciente_rut: [],
            semanabloq: [],
            rangosemanalab: [],
            errors: []
        }
    },
    async created(){
        var urlservicio = '/getservicio';
        await axios.get(urlservicio).then(response => {
            this.servicio = response.data.servicio;
        });
        var urlsemana = '/getsemana';
        await axios.get(urlsemana).then(response => {
            this.week_s = response.data.comienzo;
            this.week_e = response.data.fin;
        });
        var urlsemanabloq = '/getsemanabloq/' + this.servicio;
        await axios.get(urlsemanabloq).then(response => {
            this.semanabloq = response.data
        });
        var urlrangosemana = '/getrangosemanalaboral/' + this.servicio;
        await axios.get(urlrangosemana).then(response => {
            this.rangosemanalab = response.data
        });
        this.datefilter_start = moment().startOf('week').isoWeekday(this.week_s).format('YYYY-MM-DD');
        this.datefilter_end = moment().endOf('week').isoWeekday(this.week_e).format('YYYY-MM-DD');
        this.selectweek = moment().format('YYYY-MM-DD');
        if(this.selectweek < this.datefilter_start){
            this.selectweek = this.datefilter_start;
        }
        if(moment(this.selectweek).format('d') == 0){
            this.selectweek = moment(this.selectweek).subtract(1, 'days').format('YYYY-MM-DD');
        }
        this.diasemana = moment().startOf('week').isoWeekday(8).format('DD-MM-YYYY') + ' hasta ' + moment().endOf('week').isoWeekday(6).format('DD-MM-YYYY');
        var urlstart = '/getschedule/' + this.servicio + '/' + moment().startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment().endOf('week').isoWeekday(6).format('YYYY-MM-DD');
        var urldefaultlayout = '/getdefaultlayout/' + this.servicio + '/' + moment().startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment().endOf('week').isoWeekday(6).format('YYYY-MM-DD');
        this.getschedule(urlstart, urldefaultlayout);
    },
    methods:{
        async getschedule(urlschedule, urllayout){
            this.close_lu = false;
            this.close_ma = false;
            this.close_mi = false;
            this.close_ju = false;
            this.close_vi = false;
            this.close_sa = false;
            await axios.get(urllayout).then(response => {
                this.datadefaultpage = response.data
            });
            this.start_date = this.datadefaultpage.start_block;
            this.interval = this.datadefaultpage.interval;
            this.dataschedule = [];
            //this.start_date = this.datadefaultpage.start_block;
            var arraydata = {};
            var data = { id:'',value:'',row:'',col:'',exist:false,color:'' };
            await axios.get(urlschedule).then(response => {
                    this.listschedule = response.data.schedule;
                    this.total_boxes = response.data.boxes;
                    this.feriado_semana = response.data.feriados;
            });
            for(var i = 1; i <= this.numfilas; i++){
                arraydata = { time:'', mo:'', tu:'', we:'', th:'', fr:'', sa:'',row:'' };
                arraydata.time = moment(this.start_date,'HH:mm').format('HH:mm');
                this.start_date = moment(this.start_date,'HH:mm').add(this.interval,'m');
                for(var j = 0; j < 7; j++){
                    this.listschedule.forEach(info => {
                        if(info.row == i && info.column == j){
                            if(info.total == this.total_boxes){
                                data = { id:1,value:(info.total + '/' + this.total_boxes),row:i,col:j,exist:true,color:'no-cupo' };
                            }
                            else{
                                data = { id:1,value:(info.total + '/' + this.total_boxes),row:i,col:j,exist:true,color:'disp-cupo' };    
                            }
                        }
                    });
                    this.rangosemanalab.forEach(lab => {
                        if(lab.dia_semana == j && moment(lab.hora_maxima,'HH:mm') <= moment(arraydata.time,'HH:mm') || 1==j  && moment("15:00","HH:mm")>moment(arraydata.time,"HH:mm") || 5==j  && moment("15:00","HH:mm")>moment(arraydata.time,"HH:mm")){
                            data = { id:1,value:'',row:i,col:j,exist:true,bloqmodal:true,color:'bloq-cupo' };
                        }
                    });
                    if(j == 1){ arraydata.mo = data; }
                    if(j == 2){ arraydata.tu = data; }
                    if(j == 3){ arraydata.we = data; }
                    if(j == 4){ arraydata.th = data; }
                    if(j == 5){ arraydata.fr = data; }
                    if(j == 6){ arraydata.sa = data; }
                    data = { id:'',value:'',row:i,col:j+1,exist:false,color:'' };
                }
                arraydata.row = i;
                this.dataschedule.push(arraydata);
                if(moment(this.start_date,'HH:mm') > moment('20:00','HH:mm')){
                    break;
                }
            }
            this.feriado_semana.forEach(fer => {
                if(fer.dia_semana == 1){ this.close_lu = true; }
                if(fer.dia_semana == 2){ this.close_ma = true; }
                if(fer.dia_semana == 3){ this.close_mi = true; }
                if(fer.dia_semana == 4){ this.close_ju = true; }
                if(fer.dia_semana == 5){ this.close_vi = true; }
                if(fer.dia_semana == 6){ this.close_sa = true; }
            });
            this.semanabloq.forEach(bloq => {
                if(bloq.dia_semana == 1){ this.close_lu = true; }
                if(bloq.dia_semana == 2){ this.close_ma = true; }
                if(bloq.dia_semana == 3){ this.close_mi = true; }
                if(bloq.dia_semana == 4){ this.close_ju = true; }
                if(bloq.dia_semana == 5){ this.close_vi = true; }
                if(bloq.dia_semana == 6){ this.close_sa = true; }
            });
            this.loading_table = true;
        },
        async showmodalagend(row, col, time, exist){
            this.errors = [];
            this.selectrow = row;
            this.selectcol = col;
            this.hora_modal = time;
            if(moment(this.selectweek).format('d') == 0){
                this.selectweek = moment(this.selectweek).subtract(1, 'days').format('YYYY-MM-DD');
            }   
            this.fecha_modal = moment(this.selectweek).startOf('week').isoWeekday((8-1) + col).format('DD-MM-YYYY');
            var urlpaciente = '/getpaciente/' + this.servicio + '/' + row + '/' + col + '/' + moment(this.selectweek).startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment(this.selectweek).endOf('week').isoWeekday(6).format('YYYY-MM-DD');
            await axios.get(urlpaciente).then(response => {
                    this.datapaciente = response.data.pacientes
                    this.pacientes_count = response.data.pacientes_count
            });
        },
        getday(){
            if(this.selectcol == 1){
                return 'LUNES';
            }
            if(this.selectcol == 2){
                return 'MARTES';
            }
            if(this.selectcol == 3){
                return 'MIERCOLES';
            }
            if(this.selectcol == 4){
                return 'JUEVES';
            }
            if(this.selectcol == 5){
                return 'VIERNES';
            }
            if(this.selectcol == 6){
                return 'SABADO';
            }
        },
        cambiarfecha(){
            if(moment(this.selectweek).format('d') == 0){
                this.selectweek = moment(this.selectweek).subtract(1, 'days').format('YYYY-MM-DD');
            }
             // bug IOS safari
            if(this.selectweek < this.datefilter_start){
                this.selectweek = this.datefilter_start;
            }
            if(this.selectweek > this.datefilter_end){
                this.selectweek = moment(this.datefilter_end,'YYYY-MM-DD').subtract(1,'days').format('YYYY-MM-DD');
            }
            // end bug
            this.diasemana = moment(this.selectweek).startOf('week').isoWeekday(8).format('DD-MM-YYYY') + ' hasta ' + moment(this.selectweek).endOf('week').isoWeekday(6).format('DD-MM-YYYY');
            var urlsearch = '/getschedule/' + this.servicio + '/' + moment(this.selectweek).startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment(this.selectweek).endOf('week').isoWeekday(6).format('YYYY-MM-DD');
            var urllayout = '/getdefaultlayout/' + this.servicio + '/' + moment(this.selectweek).startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment(this.selectweek).endOf('week').isoWeekday(6).format('YYYY-MM-DD');
            this.getschedule(urlsearch, urllayout);
        },
        showdiatitletable(day){
            return moment(this.selectweek).startOf('week').isoWeekday(day).format('DD-MM-YYYY');
        },
        formatofecha(fecha){
            return moment(fecha).format('DD-MM-YYYY');
        },
        formatohora(hora){
            return moment(hora,'HH:mm:ss').format('HH:mm');
        },
        async deleteschedule(){
            var urldeleteschedule = '/deleteschedule/' + this.servicio + '/' + this.delete_reserva;
            await axios.delete(urldeleteschedule).then(response => {
                $('#confirmdelete').modal('hide');
                $('#modalAgend').modal('show');
                toastr.success('Reserva Eliminada.');
            });
            if(moment(this.selectweek).format('d') == 0){
                this.selectweek = moment(this.selectweek).subtract(1, 'days').format('YYYY-MM-DD');
            }
            var urlsearch = '/getschedule/' + this.servicio + '/' + moment(this.selectweek).startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment(this.selectweek).endOf('week').isoWeekday(6).format('YYYY-MM-DD');
            var urllayout = '/getdefaultlayout/' + this.servicio + '/' + moment(this.selectweek).startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment(this.selectweek).endOf('week').isoWeekday(6).format('YYYY-MM-DD');
            this.showmodalagend(this.selectrow,this.selectcol);
            this.getschedule(urlsearch, urllayout);
        },
        addreserva(){
            this.errors = [];
            this.rut = '';
            this.name = '';
            this.address = '';
            this.telephone = '';
            this.email = '';
            $('#modalAgend').modal('hide');
        },
        async savereserva(){
            this.id_exito = '';
            this.errors = [];
            var urlsavereserva = '/savereserva';
            await axios.post(urlsavereserva, {
                servicio: this.servicio,
                row: this.selectrow,
                column: this.selectcol,
                state: 1,
                fecha_reserva: moment(this.fecha_modal,'DD-MM-YYYY').format('YYYY-MM-DD'),
                hora_reserva: this.hora_modal,
                rut: this.rut.toUpperCase(),
                nombre: this.name.toUpperCase(),
                telefono: this.telephone,
                correo: this.email,
                direccion: this.address.toUpperCase()
            }).then(response => {
                if(moment(this.selectweek).format('d') == 0){
                    this.selectweek = moment(this.selectweek).subtract(1, 'days').format('YYYY-MM-DD');
                }
                var urlsearch = '/getschedule/' + this.servicio + '/' + moment(this.selectweek).startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment(this.selectweek).endOf('week').isoWeekday(6).format('YYYY-MM-DD');
                var urllayout = '/getdefaultlayout/' + this.servicio + '/' + moment(this.selectweek).startOf('week').isoWeekday(8).format('YYYY-MM-DD') + '/' + moment(this.selectweek).endOf('week').isoWeekday(6).format('YYYY-MM-DD');
                this.getschedule(urlsearch, urllayout);
                this.rut_exito = this.rut;
                this.id_exito = response.data.id;
                this.rut = '';
                this.name = '';
                this.address = '';
                this.telephone = '';
                this.email = '';
                if(this.id_exito == 0){
                    toastr.warning('No hay cupos disponibles para el bloque seleccionado.','Reserva');
                }
                else{
                    $('#modalAdd').modal('hide');
                    toastr.success('Reserva Ingresada.','Reserva');
                    $('#exitoPDF').modal('show');
                }
            }).catch(error => {
                for(var data in error.response.data.errors){
                    this.errors.push(error.response.data.errors[data].shift());
                }
            })
        },
        async BuscarRut(){
            var urlbuscar = '/buscarpacienterut/' + this.servicio + '/' + this.buscar_rut;
            if(this.buscar_rut != ''){
                await axios.get(urlbuscar).then(response => {
                    this.paciente_rut = response.data
                });
                if(this.paciente_rut[0] == null){
                    toastr.warning('No existen reservas bajo el rut ingresado.');
                }
            }
            else{
                this.paciente_rut = [];
            }
        },
        borrarBuscar(){
            this.paciente_rut = [];
            this.buscar_rut = '';
        },
        messagedelete(id){
            $('#modalAgend').modal('hide');
            this.delete_reserva = id;
        },
        year(){
            let year = moment().format('YYYY');
            return year;
        } 
    }
}
</script>
