<template>
  <ScrollTop>
      <a class="btn-scroll button-blue-alemana">
        <i class="fas fa-chevron-up fa-2x"></i>
      </a>
  </ScrollTop>
</template>

<style>
.btn-scroll {
    border-radius: 8px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
}
.button-blue-alemana{
    background-color: #407EC9 !important;
    color: white !important;
}
</style>

<script>
import ScrollTop from '@/components/ScrollTop'
export default {
  components: {
    ScrollTop,
  }
}
</script>


