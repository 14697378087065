<template>
        <div>
            <div class="container">
                <div class="p-5"></div>
                <div class="row align-items-center">    
                    <div class="col-md-12">
                        <div class="d-flex justify-content-center"><LogoHome /></div>         
                    </div>
                </div>           
            </div>
            <div class="row">    
                <div class="col-md-3"></div>
                <div class="col-md-6">
                    <div class="p-4"></div>
                        <div class="text-left"> 
                            <div class="card-body"></div>
                        </div>   
                    </div>            
                <div class="col-md-3"></div>   
            </div> 
                <div class="row">    
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <div class="p-1"></div>
                            <div class="card bg-light text-center"> 
                                <a href="/agenda/vacuna-covid" class="underline text-gray-900"> <div class="card-body">Agenda Hora Vacuna COVID</div></a>
                            </div>   
                        </div>            
                    <div class="col-md-3"></div>   
                </div> 
        </div>
</template>

<style>
html {
  height: 100% !important;
}
body {
    width: 99% !important;
}

a:link, a:visited, a:active a:hover{
    color: black;
    text-decoration:none;
}
</style>

<script>
import LogoHome from '@/Jetstream/LogoHome'
export default {
    components: {
            LogoHome,
        },
}
</script>